import { useMediaQuery } from '@mnd-frontend/hooks';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { formatUrl } from '../../utils/formatUrl';
import { getUriParts } from '../../utils/getUriParts';
import { ChevronDownIcon, ClearIcon, SearchIcon } from '../icons';
import { chooseLightOrDarkTextColor, moduleBackgroundToCSS } from '../modules/getModuleColors';

const Form = styled.form<{ $color: string }>`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  width: 100%;
  max-width: 30rem;
  position: relative;
  border-radius: 2rem;
  margin: 1rem 0;
  font-size: 1rem;
  color: ${({ $color }) => $color};
  input[type='search'] {
    font-size: inherit;
    width: 100%;
    padding: 1.5rem;
    box-sizing: border-box;
    height: 24px;
    border-radius: 2rem;
    background: rgba(255, 255, 255, 0.5);
    outline: 2px solid rgba(255, 255, 255, 0.25);
    &:focus {
      background: rgba(255, 255, 255, 0.85);
      outline: 2px solid rgba(255, 255, 255, 1);
    }
    &::-webkit-search-cancel-button {
      appearance: none;
    }
    &::placeholder {
      color: inherit;
    }
  }
`;

const FormButton = styled.button`
  background: transparent;
  width: calc(24px + 1.5rem);
  height: calc(24px + 1.5rem);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  &:disabled {
    color: var(--color-gray);
  }
  cursor: pointer;
  position: absolute;
  right: 3rem;
  &[type='submit'] {
    right: 0.25rem;
  }
`;

const activeListItem = ($pageBackground: string | null) => css`
  background: var(--color-white);
  color: ${$pageBackground ? moduleBackgroundToCSS($pageBackground) : 'var(--color-white)'};
`;

const Nav = styled.nav`
  margin: 1rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CategoryList = styled.ul<{ $pageBackground: string | null }>`
  display: grid;
  margin: 0;
  grid-auto-flow: column;
  justify-content: center;
  list-style: none;
  padding: 0;
  border: 1px solid var(--color-white);
  border-radius: 2rem;
  overflow: hidden;
`;

const CategoryListItem = styled.li<{ $pageBackground: string | null; $active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  font-weight: 700;
  padding: 0;
  @media (min-width: 768px) {
    border-right: 1px solid var(--color-white);
    &:last-child {
      border-right: none;
    }
  }
  ${({ $active, $pageBackground }) => $active && activeListItem($pageBackground)};
  &:hover {
    ${({ $pageBackground }) => activeListItem($pageBackground)};
  }
`;

const CategoryLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  word-break: break-all;
  padding: 0.5rem 1.5rem;
  width: 100%;
`;

const LinkSelect = styled.div<{ $listVisible: boolean }>`
  width: 100%;
  max-width: 30rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.5);
  outline: 2px solid rgba(255, 255, 255, 0.25);
  border-radius: 2rem;
  height: 48px;
  padding: 0 1.5rem;
  margin: 1rem auto;
  color: var(--text-color);
  position: relative;
`;

const OpenLinkSelectButton = styled(FormButton)<{ $listOpen: boolean }>`
  right: 0;
  width: 100%;
  justify-content: flex-end;
  padding-right: 1rem;
  span {
    transition: transform 0.3s ease-in-out;
    transform: rotate(${({ $listOpen }) => ($listOpen ? '180deg' : '0')});
  }
`;

const MobileCategoryList = styled.ul`
  position: absolute;
  top: 31px;
  left: 0;
  z-index: 1;
  width: 100%;
  background: var(--color-deep-purple);
  color: var(--color-white);
  padding: 1rem;
  list-style: none;
`;

const MobileCategoryListItem = styled.li<{ $pageBackground: string | null; $active: boolean }>`
  padding: 0.25rem 0;
`;

type Categories = {
  __typename?: 'Category' | 'ResourceCategory' | 'CustomerCaseCategory';
  name: string | null;
  link: string | null;
  slug: string | null;
}[];

const ArchivePageSearch = ({
  pageBackground,
  loading,
  search,
  categories,
  pageUri,
  onSubmit,
  onChange,
  setSearch,
  onClick,
}: {
  pageBackground: string | null;
  loading: boolean;
  search: string;
  categories: Categories;
  pageUri: string | null;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setSearch: (value: string) => void;
  onClick: (navigation_selection?: string | null) => void;
}) => {
  const { t } = useTranslation();
  const pathname = usePathname();

  const categorySlug = getUriParts(pathname).category ?? 'all';
  const activeCategory = categories.find(category => category.slug === categorySlug) || null;

  const [showList, setShowList] = useState(false);
  const isMobile = useMediaQuery('@media (max-width: 767px)');
  const textColor = chooseLightOrDarkTextColor(pageBackground);

  return (
    <>
      <Form method="POST" onSubmit={onSubmit} $color={textColor}>
        <input type="search" placeholder={t('common_search')} onChange={onChange} value={search} />
        {!!search.length && (
          <FormButton
            type="button"
            aria-label="clear search input"
            onClick={() => setSearch('')}
            disabled={loading}
          >
            <span aria-hidden="true">
              <ClearIcon />
            </span>
          </FormButton>
        )}
        <FormButton type="submit" disabled={loading}>
          <span aria-hidden="true">
            <SearchIcon />
          </span>
        </FormButton>
      </Form>
      {categories.length > 0 ? (
        <Nav>
          {isMobile ? (
            <LinkSelect $listVisible={showList}>
              <span aria-label="current category" id="currentCategory">
                {activeCategory ? activeCategory.name : t('cm_public_all_categories')}
              </span>
              <OpenLinkSelectButton
                type="button"
                aria-label="toggle link list"
                aria-expanded={showList}
                aria-controls="linkList"
                onClick={() => setShowList(val => !val)}
                $listOpen={showList}
              >
                <span aria-hidden="true">
                  <ChevronDownIcon />
                </span>
              </OpenLinkSelectButton>
              <MobileCategoryList
                id="linkList"
                role="listbox"
                aria-labelledby="currentCategory"
                hidden={!showList}
              >
                <MobileCategoryListItem
                  role="option"
                  $active={!activeCategory}
                  $pageBackground={pageBackground}
                >
                  <Link href={formatUrl(pageUri)} prefetch={false}>
                    {t('cm_public_all_categories')}
                  </Link>
                </MobileCategoryListItem>
                {categories.map((category, i) => (
                  <MobileCategoryListItem
                    key={i}
                    role="option"
                    $active={category.slug === activeCategory?.slug}
                    $pageBackground={pageBackground}
                  >
                    <Link
                      key={i}
                      href={formatUrl(category.link)}
                      onClick={() => onClick(category?.name)}
                      prefetch={false}
                    >
                      {category?.name}
                    </Link>
                  </MobileCategoryListItem>
                ))}
              </MobileCategoryList>
            </LinkSelect>
          ) : (
            <CategoryList $pageBackground={pageBackground}>
              <CategoryListItem $active={!activeCategory} $pageBackground={pageBackground}>
                <CategoryLink href={formatUrl(pageUri)} prefetch={false}>
                  {t('cm_public_all_categories')}
                </CategoryLink>
              </CategoryListItem>
              {categories.map((category, i) => (
                <CategoryListItem
                  key={i}
                  $active={category.slug === activeCategory?.slug}
                  $pageBackground={pageBackground}
                >
                  <CategoryLink
                    key={i}
                    href={formatUrl(category.link)}
                    onClick={() => onClick(category?.name)}
                    prefetch={false}
                  >
                    {category?.name}
                  </CategoryLink>
                </CategoryListItem>
              ))}
            </CategoryList>
          )}
        </Nav>
      ) : null}
    </>
  );
};

export default ArchivePageSearch;
