export const getUriParts = (
  path: string,
): {
  uri: string;
  searchValue?: string;
  isPreview: boolean;
  page?: number;
  category?: string;
} => {
  const result =
    /^(?<uri>.*?)(?<categoryPartOfUri>\/(category|resource-categories|customer-case-categories)\/(?<category>[^/]+))?(\/s:(?<searchValue>[^/]+?))?(\/page\/(?<pageNumber>\d+))?(?<preview>\/_preview)?\/?$/.exec(
      decodeURIComponent(path.trim()),
    );
  if (!result) {
    return {
      uri: path,
      searchValue: undefined,
      page: undefined,
      isPreview: false,
      category: undefined,
    };
  }
  // eslint-disable-next-line prefer-const
  let { uri, searchValue, pageNumber, preview, category, categoryPartOfUri } = result.groups as {
    searchValue?: string;
    uri: string;
    pageNumber?: string;
    preview?: string;
    category?: string;
    categoryPartOfUri?: string;
  };

  // category is needed in uri for BE to find the archive page
  uri = categoryPartOfUri ? `${uri}${categoryPartOfUri}` : uri;

  return {
    uri: uri.endsWith('/') ? uri : `${uri}/`,
    searchValue: searchValue ?? undefined,
    page: pageNumber ? Number(pageNumber) : undefined,
    isPreview: !!preview,
    category,
  };
};
